export const configurationIdentityServer = {
    client_id: 'misweb',
    authority:"https://uatreporting.nordic.amexgbt.com/NordicIdentity",
    redirect_uri: window.location.origin + '/authentication/callback',
    silent_redirect_uri: window.location.origin + '/authentication/silent-callback',
    //silent_login_uri:window.location.origin + '/authentication/silent-login',
    scope: 'openid profile miswebapi role',
    response_type: 'code',
    token_renew_mode: "access_token_invalid",
    refresh_time_before_tokens_expiration_in_second: 90,
    service_worker_relative_url:'/OidcServiceWorker.js',
    service_worker_only: true,
    storage: sessionStorage,
    //silent_login_timeout: 3333000
    monitor_session:false
  };


